import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Services = styled.div`
  ${tw`flex flex-wrap -mx-3`};
`;

export const ServiceItem = styled.div`
  ${tw`w-full sm:w-1/2`};
`;

export const Contain = styled.div`
  ${tw`flex flex-wrap max-w-screen-lg w-full mx-auto p-2 py-8 sm:py-16`};
`;
